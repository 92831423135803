<template>
  <div>
    <div>
      <label v-if="title" class="custom-label">
        {{ title }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <br v-if="title" />

      <v-autocomplete
        :items="optionsSort"
        :name="name"
        :placeholder="'-- ' + placeholder + ' --'"
        variant="outlined"
        v-model="itemsValue"
        :model-value="itemsValue || null"
        @update:modelValue="val => updateModelValue(val)"
        :item-value="valueField"
        :item-title="textField"
        ref="input"
        :disabled="disabled"
        single-line
        hide-details
        color="#dbdbdb"
        :error="isValidate"
        autocomplete="off"
        @update:focused="clearField"
      >
        <template v-slot:prepend-item>
          <v-list-item :value="null" disabled selected v-if="value == null">
            <span v-if="options.length > 0">
              --
              <slot name="options-name">{{
                placeholder || "กรุณาเลือกข้อมูล"
              }}</slot>
              --</span
            >
            <span v-else>-- ไม่มีข้อมูล --</span>
          </v-list-item>
          <v-list-item
            :value="'N/A'"
            disabled
            selected
            v-else-if="value == 'N/A'"
          >
            <span v-if="options.length > 0">
              --
              <slot name="options-name">{{
                placeholder || "กรุณาเลือกข้อมูล"
              }}</slot>
              --</span
            >
            <span v-else>-- ไม่มีข้อมูล --</span>
          </v-list-item>
          <v-list-item value="" disabled selected v-else>
            <span v-if="options.length > 0">
              --
              <slot name="options-name">{{
                placeholder || "กรุณาเลือกข้อมูล"
              }}</slot>
              --</span
            >
            <span v-else>-- ไม่มีข้อมูล --</span>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>

    <template v-if="eachValid">
      <div v-if="eachValid.$error && v && v.$error">
        <span class="text-error" v-if="v.required.$invalid">{{
          validateText || "กรุณาเลือกข้อมูล"
        }}</span>
        <span class="text-error" v-else-if="!v.required">{{
          validateText || "กรุณาเลือกข้อมูล"
        }}</span>
        <span
          class="text-error"
          v-else-if="v.minValue && v.minValue.$invalid"
          >{{ options[0][textField || "text"] }}</span
        >
        <span
          class="text-error"
          v-if="v.valueDefault && v.valueDefault.$invalid"
          >{{ options[0][textField || "text"] }}</span
        >
      </div>
    </template>
    <template v-else>
      <div v-if="v && v.$error">
        <span class="text-error" v-if="v.required.$invalid || !v.required">{{
          validateText || "กรุณาเลือกข้อมูล"
        }}</span>

        <span
          class="text-error"
          v-else-if="v.minValue && v.minValue.$invalid"
          >{{ options[0][textField || "text"] }}</span
        >
        <span
          class="text-error"
          v-if="v.valueDefault && v.valueDefault.$invalid"
          >{{ options[0][textField || "text"] }}</span
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false
    },
    text: {
      required: false
    },
    name: {
      required: false
    },
    size: {
      required: false
    },
    options: {
      required: true,

      default: () => []
    },
    value: {
      required: false
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false
    },
    v: {
      required: false
    },
    valueField: {
      required: false
    },
    textField: {
      required: false
    },
    disabled: {
      required: false
    },
    placeholder: {
      required: false
    },
    validateText: {
      required: false
    },
    eachValid: {
      required: false,
      type: Object,
      default: null
    },
    formType: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      selected: 0,
      dataValue: null
    };
  },

  created() {
    this.setOtherValue();
  },
  mounted() {
    this.setDefault();
  },
  watch: {
    options: {
      handler: function (val, oldVal) {
        if (this.formType) {
          if (
            val.length != oldVal.length &&
            val.find(el => el.name == this.value)
          )
            return;
          if (
            val.length == oldVal.length &&
            val.find(el => el.name == this.value)
          )
            return;
          if (
            (val.length != oldVal.length || val.length == oldVal.length) &&
            !val.find(el => el.name == this.value)
          )
            return this.setOtherValue(val);
        }
      },
      deep: true
    }
  },
  computed: {
    itemsValue() {
      return this.value;
    },
    optionsSort() {
      return this.options.sort((a, b) => a.sort_order - b.sort_order);
    }
  },
  methods: {
    setOtherValue(val) {
      if (this.formType)
        if (this.value && this.options.length > 0) {
          let value = this.options.find(el => el.name == this.value);
          if (!value) {
            let other = this.options.find(el => el.choice_type_id == 3);
            this.$emit("setOtherValue", this.value);
            this.$emit("update:value", other ? other.name : "");
          }
        }
    },
    setDefault() {
      if (this.formType) {
        if (this.value) return;
        this.$nextTick(() => {
          let check = this.options.find(el => el.isDefault == 1);

          if (check) {
            this.$emit("update:value", check.name);
          }
        });
      }
    },

    updateModelValue(val) {
      let find = this.options.find(el => el.name == val);

      if (find) if (find.choice_type_id == 3) this.$emit("deleteOtherValue");

      this.$emit("change", val);
      this.$emit("update:value", val);
    },
    focus() {
      this.$refs.input.focus();
    },
    clearField(val) {
      if (!val) {
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.select-custom {
  // margin-bottom: 15px;
  position: relative;
  white-space: nowrap;

  // padding-bottom: 15px;
}
.select-custom.error {
  border-color: red !important;
}
.select-custom.error > .btn-select {
  border-color: red !important;
}
.select-custom > label {
  // color: gray;
  font-size: var(--text-lg);
  margin-bottom: 5px;
  width: 100%;
}
::v-deep .btn-select {
  color: #575757;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 0.25rem;
  padding: 5px 10px;
  cursor: pointer;
  height: 45px;
}
::v-deep .btn-select.custom-select-lg {
  height: 45px;
  font-size: 1rem;
}

::v-deep .btn-select:focus {
  border-color: gray;
}
::v-deep .btn-select > option {
  background-color: white;
  color: gray;
}
::v-deep .btn-select > option:checked {
  background-color: gray !important;
  color: white !important;
}
::v-deep .btn-select:focus {
  box-shadow: unset !important;
}
</style>
