const action = [
  {
    id: 2,
    action: "ดึงข้อมูล menu",
    url: "/api/v1/menu",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล Setting",
    url: "/api/v1/setting/getline",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล theme",
    url: "/api/v1/Setting/theme",
    path: ""
  },
  {
    id: 2,
    action: "เช็คว่ามี user หรือไม่",
    url: "/api/v1/user/CheckSocialBind",
    path: ""
  },
  {
    id: 6,
    action: "กดปุ่มเข้าสู่ระบบ หน้า re-login",
    url: "/api/v1/user/EditUserProfile",
    path: "/review"
  },
  {
    id: 6,
    action: "แก้ไขข้อมูลสมาชิก",
    url: "/api/v1/user/EditUserProfile",
    path: "/manage/profile"
  },
  {
    id: 2,
    action: "ดึงข้อมูล custom field",
    url: "/api/v1/user/GetUserRegisterCustomFieldList",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูลหัวข้อ custom object",
    url: "/api/CustomObject/custom_object_head",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล custom object modal",
    url: "/api/CustomObject/custom_object_register_page",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล social media",
    url: "/api/v1/user/GetUserSocialMediaList",
    path: ""
  },
  {
    id: 6,
    action: "กดปุ่มเข้าสู่ระบบ หน้า re-login",
    url: "/api/v1/user/update_custom_answer",
    path: "/review"
  },
  {
    id: 6,
    action: "เข้าหน้า upload document",
    url: "/api/v1/user/GetUserDocument",
    path: "/review"
  },
  {
    id: 6,
    action: "เข้าหน้า upload document",
    url: "/api/v1/setting/GetDocumentSetting",
    path: "/review"
  },
  {
    id: 6,
    action: "อัพโหลดเอกสาร",
    url: "/api/v1/user/EditDocument",
    path: "/review"
  },
  {
    id: 6,
    action: "เชื่อม line กับ user หลัง",
    url: "/api/v1/user/pair",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล user",
    url: "/api/v1/user/GetUserProfile",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล member user",
    url: "/api/v1/user/getmember/1",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล banner",
    url: "/api/v1/banner/campaign_form",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล banner friend get friend",
    url: "/api/v1/FriendGetFriend/get",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล market list",
    url: "/api/v1/User/get/marketplace",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล complete profile",
    url: "/api/v1/User/GetCompleteProfile",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล mission ทั้งหมด",
    url: "/api/v1/user/GetMissionList",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล mission list",
    url: "api/v1/Mission/list",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล custom object modal",
    url: "/api/v1/User/get/custom_object_register_page",
    path: ""
  },
  {
    id: 2,
    action: "กดปุ่ม logout",
    url: "/api/v1/user/unpair",
    path: ""
  },
  {
    id: 2,
    action: "get token",
    url: "/api/v1/user/LoginExternal",
    path: ""
  },
  {
    id: 2,
    action: "กดปุ่มยืนยันเบอร์โทรศัพท์",
    url: "/api/v1/User/ValidateTelephone",
    path: ""
  },
  {
    id: 2,
    action: "ส่งรหัส otp",
    url: "/api/v1/user/SendOTP",
    path: ""
  },
  {
    id: 2,
    action: "ยืนยัน otp",
    url: "/api/v1/user/VerifyOTP",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล privilege",
    url: "/api/v1/Privilege/privilege_list",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล privilege history",
    url: "/api/v1/Privilege/privilege_history",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล privilege detail",
    url: "/api/v1/Privilege/coupon_detail",
    path: ""
  },
  {
    id: 2,
    action: "ยืนยันการใช้งาน coupon",
    url: "/api/v1/Reward/redeem_coupon",
    path: ""
  },
  {
    id: 2,
    action: "ยืนยันกรอก coupon code",
    url: "/api/v1/privilege/redeemprivilege",
    path: ""
  },
  {
    id: 2,
    action: "ยันยืนรับของจัดส่งที่บ้าน",
    url: "/api/v1/Redeem/redeem_pick_at_home",
    path: ""
  },
  {
    id: 2,
    action: "ยันยืนรับของรับที่สาขา",
    url: "/api/v1/redeem/redeem_item",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล reward history",
    url: "/api/v1/Reward/group_reward_history",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล reward list",
    url: "/api/v1/Reward/reward_list",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูลหน้า Mission Detail",
    url: "/api/v1/Mission/get",
    path: ""
  },
  {
    id: 2,
    action: "รับของรางวัล Mission",
    url: "/api/v1/Mission/RedeemReward",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูลสาขา สำหรับรับรางวัล",
    url: "/api/v1/Reward/redeem_coupon",
    path: "/mission"
  },
  {
    id: 2,
    action: "กดเลือกสาขารับรางวัล",
    url: "/api/v1/Mission/UpdateReward",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล receipt form",
    url: "/api/v1/ReceiptScanner/get",
    path: ""
  },
  {
    id: 2,
    action: "กดปุ่มส่งใบเสร็จ",
    url: "/api/v1/ReceiptScanner/submit",
    path: ""
  },
  {
    id: 2,
    action: "ดึงสถานะของ Receipt",
    url: "/api/v1/ReceiptScanner/GetUserReceiptScannerStatus",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล Receipt ที่เคยส่ง",
    url: "/api/v1/ReceiptScanner/getReceiptHistory",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล redeem",
    url: "/api/v1/redeem/info",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล branch redeem",
    url: "/api/v1/Redeem/get_branch_list",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูลที่อยู่ในการรับของขวัญ",
    url: "/api/v1/User/VerifyAddress",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูลพ้อย",
    url: "/api/v1/user/PointLogByBranch",
    path: ""
  },
  {
    id: 2,
    action: "ดึงเขต / ตำบล",
    url: "/api/v1/Service/Getsubaddress",
    path: ""
  },
  {
    id: 2,
    action: "ดึงรายละเอียดข่าวสาร",
    url: "/api/v1/MonthlyPrivilege/131",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูลข่าวสาร",
    url: "/api/v1/MonthlyPrivilege/list",
    path: ""
  },
  {
    id: 2,
    action: "ดึงข้อมูล transaction code",
    url: "/api/RandomReward/list_redeem_order_item",
    path: ""
  },
  {
    id: 2,
    action: "กดยืนยันกรอกโค้ด transaction code",
    url: "/api/v1/TransactionCode/RedeemtransactionCode",
    path: ""
  },
  {
    id: 2,
    action: "ดึงรายละเอียดข่าวสาร",
    url: "/api/v1/MonthlyPrivilege/131",
    path: ""
  },
  {
    id: 2,
    action: "ส่ง saleorder",
    url: "/api/saleOrder/customer",
    path: ""
  }
];
export { action };
