<template>
  <div class="d-none" v-if="modalShow">
    <v-dialog
      v-model="modalShow"
      class="confirmModal"
      ref="confirmModal"
      hide-header
      hide-footer
      no-close-on-backdrop
      centered
      body-class="p-4"
    >
      <div class="text-center">
        <span class="f-bold f-xl" v-if="header">{{ header }}</span>
        <p class="text-text f-lg mt-3" v-if="text" v-html="text"></p>
        <p class="text-sub f-md" v-if="subText" v-html="subText"></p>
      </div>
      <hr />
      <div class="text-center mt-3">
        <c-btn variant="confirm-primary" block @click="handleOkModal">
          {{ button.true.msg }}</c-btn
        >
        <c-btn variant="outline-primary" block @click="handleCloseModal">
          {{ button.false.msg }}</c-btn
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      required: false,
      type: String
    },
    text: {
      required: false,
      type: String
    },
    subText: {
      required: false,
      type: String
    },
    button: {
      required: false,
      type: Object,
      default: () => {
        return {
          true: {
            class: ["btn-success"],
            msg: "ยืนยัน"
          },
          false: {
            class: ["btn-danger"],
            msg: "ยกเลิก"
          }
        };
      }
    }
  },
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    close() {
      this.modalShow = false;
    },
    handleOkModal() {
      this.$emit("handler", true);
      this.modalShow = false;
    },
    handleCloseModal() {
      this.modalShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.confirmModal {
  width: auto;
}
.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  margin-bottom: 0;

  word-wrap: break-word !important;
}
.text-sub {
  color: #afce38;

  word-wrap: break-word !important;
}
.modal-body {
  margin: auto;
  text-align: center;
}
</style>
