<template>
  <div>
    <template v-if="lineLayout == 'Compact'">
      <div class="navbar justify-content-start layout-2">
        <div>
          <img
            :src="$store.state.domain + $store.state.theme.themeLogoLine"
            class="logo-img"
            @click="toProfile"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="navbar justify-content-center layout-1">
        <div v-if="!isShowing">
          <img
            :src="$store.state.domain + $store.state.theme.themeLogoLine"
            class="logo-img"
            @click="toProfile"
          />
        </div>
        <div v-else>
          <img
            :src="$store.state.domain + $store.state.theme.themeLogoLine"
            class="logo-img"
            @click="toProfile"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    isShowing() {
      if (this.$cookies.get("token")) return true;
      return false;
    }
  },

  methods: {
    toProfile() {
      if (
        this.$route.path == "/register" ||
        this.$route.path == "/review" ||
        this.$route.path == "/validatetelephone"
      ) {
        return;
      }
      this.$router.push("/profile");
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-black {
  background-color: #000;
}

.btn-go-home {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}
.navbar {
  overflow: hidden;
  position: sticky;
  top: 0;

  width: 100%;
  z-index: 5;

  background-color: var(--theme-navigation-bar-color);
  transition: background-color 0.1s linear;
  box-shadow: 1px 1px 5px -3px black;
}
.layout-1 {
  height: 80px;
  .logo-img {
    width: auto;
    max-width: 200px;
    height: auto;
    max-height: 65px;
  }
}
.layout-2 {
  height: 70px;
  padding: 0 10px;
  .logo-img {
    width: auto;
    max-width: 200px;
    height: auto;
    max-height: 50px;
  }
}
</style>
